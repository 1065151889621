import { Link } from "gatsby";
import React, { Component } from "react";
import Img from "gatsby-image";
import Moment from 'react-moment';
import caret from './../images/caret.svg';

import "./articleCard.css";

class ArticleCard extends Component {
	constructor(props) {
		super(props);
        this.state = {
        };
	}

	componentDidMount() {
       
    }
    
	render() {
		return (
			<article className="article article--preview" key={this.props.id}>
                <section className="article__top">
                    <Link to={this.props.link.replace("http://localhost:8000", "")} className="article__link"><h1 className="article__headline" dangerouslySetInnerHTML={{__html: this.props.title}}></h1></Link>
                    <Link to={this.props.link.replace("http://localhost:8000", "")} className="article__link"><h2 className="article__subheadline" dangerouslySetInnerHTML={{__html: this.props.subheadline}}></h2></Link>

                    <div className="article__meta">
                        <div className="article__author">
                            { this.props.author && this.props.author.acf.avatar_image ?  <Img className="article__author__image" sizes={this.props.author.acf.avatar_image.localFile.childImageSharp.sizes } /> : '' }
                            <span className="article__author__name">{this.props.author.name}</span>
                            <Moment className="article__date" format="MMMM D, YYYY">{this.props.date}</Moment>
                        </div>
                        <div className="article__categories">
                            <Link to={"/" + this.props.parentCategory.slug + "/" + this.props.subCategory.slug} className="article__categories__link">{this.props.subCategory.name}</Link>, <Link to={"/" + this.props.parentCategory.slug} className="article__categories__link">{this.props.parentCategory.name}</Link>
                        </div>
                    </div>

                </section>
            
                { this.props.media ?  <Link to={this.props.link} className="article__link"><Img className="article__image" alt={this.props.media.alt_text} sizes={this.props.media.localFile.childImageSharp.sizes} /></Link> : '' }
                { this.props.excerpt ?  <div className="article__excerpt" dangerouslySetInnerHTML={{__html: this.props.excerpt}}></div> : '' }
                <p><Link to={this.props.link.replace("http://localhost:8000", "")} className="article__link article__link--readmore">Continue reading <img src={caret} className="caret" alt="" /></Link></p>
            </article>
		)
	}
}

export default ArticleCard;