import React, { Component } from "react"
import { graphql } from "gatsby"
//import PostIcons from "../components/post-icons"
import SEO from '../components/seo';
import Layout from "../components/layout";
import ArticleCard from '../components/articleCard';
import Pagination from '../components/pagination';

//import { rhythm } from "../utils/typography"

class CategoryTemplate extends Component {
  render() {
    let currentCat = this.props.data.wordpressCategory;
    let posts = this.props.data.allWordpressPost.edges;
    var cat = currentCat;
    var subCat = currentCat;

    if( currentCat.wordpress_parent !== 0 ){
        cat = this.props.data.wordpressCategory.parent_element;
    } 

    return (
        <Layout data={this.props.data} category={cat} subCategory={subCat}>
            <SEO title={currentCat.name} />
            {
                posts.length > 0 ?
                    posts.map(node => {
                        let parentCat = null;
                        let subCat = null;
                
                        node.node.categories.forEach(cat => {
                            if( cat.parent_element ){
                                parentCat = cat.parent_element;
                                subCat = {
                                    slug: cat.slug,
                                    name: cat.name,
                                    wordpress_id: cat.wordpress_id
                                };
                            } else {}
                        });

                        return (
                            <ArticleCard key={node.node.id} id={node.node.id} link={"/" + parentCat.slug + "/" + subCat.slug + "/" + node.node.slug} title={node.node.title} media={node.node.featured_media} excerpt={node.node.excerpt} subheadline={node.node.acf.sub_headline} author={node.node.author} date={node.node.date} parentCategory={parentCat} subCategory={subCat} />
                        );
                    })
                : null
            }
            { this.props.pageContext.numPages > 1 ? <Pagination pageContext={this.props.pageContext} catSlug={currentCat.slug} category={cat} subCategory={subCat} /> : null }
        </Layout>
    )
  }
}

export default CategoryTemplate;

export const pageQuery = graphql`
    query($id: String!, $skip: Int!, $limit: Int!) {
        wordpressCategory(id: { eq: $id }) {
            id
            name
            slug
            description
            link
            wordpress_id
            wordpress_parent
            parent_element {
                wordpress_id
                id
                name
                slug
            }
        }
        allWordpressPost(
            limit: $limit
            skip: $skip
            sort: {fields: date, order: DESC}
            filter: {
                categories: {
                    elemMatch: {
                        id: {
                            eq: $id
                        }
                    }
                }
                status: {
                    eq: "publish"
                }
            }
        ) {
            edges {
                node {
                    id
                    date
                    title
                    slug
                    excerpt
                    author {
                        name
                        acf {
                            avatar_image {
                                localFile {
                                    childImageSharp {
                                        sizes(maxWidth: 100 ) {
                                            ...GatsbyImageSharpSizes
                                        }
                                        fluid(maxWidth: 100, maxHeight: 100) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                    date
                    categories {
                        name
                        slug
                        id
                        wordpress_parent
                        wordpress_id
                        parent_element {
                            name
                            wordpress_id
                            slug
                        }
                    }
                    link
                    featured_media {
                        localFile {
                            childImageSharp {
                                sizes(maxWidth: 2064 ) {
                                ...GatsbyImageSharpSizes
                                }
                                fluid {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    acf {
                        sub_headline
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
            }
        }
        primaryCategories: allWordpressCategory(filter: {wordpress_parent: {eq: 0}, wordpress_id: {ne: 1}}, sort: {fields: name}) {
            edges {
                node {
                    count
                    id
                    slug
                    name
                    wordpress_parent
                    wordpress_id
                }
            }
        }
        secondaryCategories: allWordpressCategory(filter: {wordpress_parent: {ne: 0}}, sort: {fields: name}) {
            edges {
                node {
                    count
                    id
                    slug
                    name
                    wordpress_parent
                    wordpress_id
                    parent_element {
                        slug
                        name
                    }
                }
            }
        }
    }
`;