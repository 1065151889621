import { Link } from "gatsby";
import React, { Component } from "react";

import "./pagination.css";

class Pagination extends Component {
    constructor(props) {
        super(props);
    }
	
	render() {
        const that = this;

        const slug = (function(){
            if( (that.props.category.wordpress_id === 0) && (that.props.subCategory.wordpress_id === 0) ){
                return "/blog";
            } else if( that.props.category.wordpress_id === that.props.subCategory.wordpress_id ){
                return "/" + that.props.category.slug;
            } else {
                return "/" + that.props.category.slug + "/" + that.props.subCategory.slug;
            }
        })();

        const firstButton = this.props.pageContext.currentPage !== 1 ? <Link to={slug} className="pagination__first">First</Link> : null;
        const lastButton = this.props.pageContext.currentPage !== this.props.pageContext.numPages ? <Link to={slug + "/" + this.props.pageContext.numPages} activeClassName="active" className="pagination__last">Last</Link> : null;

		return (
			<section className="pagination">
                { firstButton }
                {this.props.pageContext.numPages > 1 ?
                    [...Array(this.props.pageContext.numPages)].map((e, i) => {
                        let page = i + 1;
                        return page === 1 ? <Link key={i} className="pagination__item" activeClassName="active" to={slug}>{page}</Link> : <Link key={i} className="pagination__item" activeClassName="active" to={slug + "/" + page}>{page}</Link>;
                    }) : null
                }
                { lastButton }
            </section>
		)
	}
}

export default Pagination;